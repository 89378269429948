<template>
    <div>
        <slot name="subtitle">
            <UIHeading tag="h1" role="subtitle" class="mb-2.5 md:mb-4">
                <slot name="subtitle-text"></slot>
            </UIHeading>

        </slot>

        <div class="flex items-center md:items-end mb-7.5 md:mb-10">
            <slot name="title">
                <UIHeading tag="h2">
                    <slot name="title-text"></slot>
                </UIHeading>
            </slot>

            <ULink class="hidden md:block ml-auto font-bold text-blue-700 underline hover:no-underline hover:text-blue-800" v-if="readMoreLink" :to="readMoreLink">See All Services</ULink>
        </div>

        <div class="grid md:grid-cols-2 gap-5 md:gap-7.5">
            <UITestimonial v-for="testimonial of testimonials" :location="testimonial.location" :name="testimonial.name" :score="testimonial.score">
                <template #title>
                    {{testimonial.title}}
                </template>
                {{testimonial.text}}
            </UITestimonial>

            <div class="flex justify-center">
                <ULink class="md:hidden font-bold text-blue-700 underline hover:no-underline hover:text-blue-800" v-if="readMoreLink" :to="readMoreLink">See All Services</ULink>
            </div>
        </div>
    </div>

</template>
<script setup>
defineProps(['readMoreLink']);

const {body: testimonials} = await queryContent('data/testimonials').findOne();
</script>
